import React from 'react';

const footer = () =>{
    return (
    <div className='footer'>
        <p>&copy; kutija.net | v1.52a by Nemanja</p>
        {/* <script src={snow}></script> */}
    </div>
    );
  }
  export default footer;